// import React from "react";
import "./NotificationCard.scss";

const NotificationCard = () => {
  return (
    <div className="under-construction-card">
      <h2>Pardon Our Dust!</h2>
      <p>
        We're currently updating our website to improve your experience. Check
        back soon for some great new features!
      </p>
    </div>
  );
};

export default NotificationCard;
