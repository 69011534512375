import permalink from '../../assets/icons/svg/permalink.svg';
import ProjectsList from '../../components/ProjectsList';

import { motion } from "framer-motion";

import './Projects.scss';

const Projects = () => {
    return (
        <motion.div className="projects-main" initial={{ opacity: 0, scale: 0.5, duration: 1 }} animate={{ opacity: 1, scale: 1, duration: 1 }} exit={{ opacity: 0, scale: 0.5, duration: 1 }}>
            <div className="container-fluid">
                <div className="page-content">
                    <div className="projects">
                        <div className="col-12">
                            <div className="section-header">
                                <h1>PROJECTS</h1>
                                <div className="count">
                                    <span>4</span>
                                    <img src={permalink} alt="permalink" />
                                </div>
                            </div>
                        </div>
                        <ProjectsList />
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Projects; 