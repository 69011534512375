import { Routes, Route } from "react-router-dom";

import Home from "../pages/home/Home";
import Projects from "../pages/project/Projects";
import ProjectDetail from "../pages/porjectDetail/ProjectDetail";
import About from "../pages/about/About";

import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes(data) {
    return (
        <AnimatePresence>
            <Routes location={data.location} key={data.location.pathname} >
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/about" element={<About />} />
                <Route path="/project-detail/:name" element={<ProjectDetail />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;