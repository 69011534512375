import { useLocation } from "react-router-dom";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import AnimatedRoutes from './components/AnimatedRoutes';

import ScrollToTop from "react-scroll-to-top";
import ScrollTop from "./components/ScrollTop";

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP);

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    document.getElementById("scroll-indicator").classList.add('active');
    const elementScroll = (document.body.scrollTop || document.documentElement.scrollTop);
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPos = (elementScroll / windowHeight) * 100;
    if(scrollPos <= 20) {
        document.getElementById("scroll-indicator-bar").style.top = (0) + "%";
    } else {
        document.getElementById("scroll-indicator-bar").style.top = (scrollPos - 20) + "%";
    }
    setTimeout(() => {
        document.getElementById("scroll-indicator").classList.remove('active');
    }, 1500);
}

function App() {
    const location = useLocation();

    return (
        <div className="site-main">
            <ScrollTop />
            <Header currentPage={location.pathname} />
            <div className="main-pages">
                <AnimatedRoutes location={location} />
                <ScrollToTop smooth top={350} className="scroll-top-button" />
                <div id="scroll-indicator">
                    <div id="scroll-indicator-bar"></div>
                </div>
            </div>
            { !location.pathname.includes('project-detail') ? <Footer /> : '' }
        </div>
    );
}

export default App;