import { NavLink } from "react-router-dom";


import luminsImage from "../assets/images/lumins-pic-3.webp";
import kycImage from "../assets/images/kyc-pic-2.webp";
import commodityImage from "../assets/images/commodity-pic-2.webp";
import mesImage from "../assets/images/mes-pic-1.webp";

import arrowRight from "../assets/icons/svg/arrow-right.svg";

const ProjectsList = () => {
  return (
    <div className="section-body columns">
      <div className="row">
        <div className="col-md-6 col-12 column">
          <NavLink to="/project-detail/conveyancing" className="card">
            <div className="card-img-wrap">
              <img src={luminsImage} alt="card" className="card-image" />
            </div>
            <div className="bottom-title">
              <img src={arrowRight} alt="arrow" />
              <h4>AI-Powered Conveyancing Platform</h4>
            </div>
          </NavLink>
        </div>
        <div className="col-md-6 col-12 column">
          <NavLink to="/project-detail/mes" className="card">
            <div className="card-img-wrap">
              <img src={mesImage} alt="card" className="card-image" />
            </div>
            <div className="bottom-title">
              <img src={arrowRight} alt="arrow" />
              <h4>Pharmaceutical Unified Platform</h4>
            </div>
          </NavLink>
        </div>
        <div className="col-md-6 col-12 column">
          <NavLink to="/project-detail/kyc" className="card">
            <div className="card-img-wrap">
              <img src={kycImage} alt="card" className="card-image" />
            </div>
            <div className="bottom-title">
              <img src={arrowRight} alt="arrow" />
              <h4>KYC Platform</h4>
            </div>
          </NavLink>
        </div>
        <div className="col-md-6 col-12 column">
          <NavLink to="/project-detail/commodity" className="card">
            <div className="card-img-wrap">
              <img src={commodityImage} alt="card" className="card-image" />
            </div>
            <div className="bottom-title">
              <img src={arrowRight} alt="arrow" />
              <h4> Commodity Trading Arbitrage Detection</h4>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ProjectsList;
