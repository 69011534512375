import "./ProjectDetail.scss";
// import image from "../../assets/images/image1.webp";

import conveyancingImage from "../../assets/images/lumins-pic-3.webp";
import kycImage from "../../assets/images/kyc-pic-2.webp";
import commodityImage from "../../assets/images/commodity-pic-2.webp";
import mesImage from "../../assets/images/mes-pic-1.webp";

import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

import { useEffect } from "react";

const ProjectDetail = () => {
  const { name } = useParams();
  let imageUrl = "";
  let projectTitle = "";

  if (name === "conveyancing") {
    projectTitle = "AI-Powered Conveyancing Platform";
    imageUrl = conveyancingImage;
  } else if (name === "mes") {
    projectTitle = "Pharmaceutical Unified Platform";
    imageUrl = mesImage;
  } else if (name === "kyc") {
    projectTitle = "KYC Platform";
    imageUrl = kycImage;
  } else if (name === "commodity") {
    projectTitle = "Commodity Trading Arbitrage Detection";
    imageUrl = commodityImage;
  }

  const contents = {
    conveyancing: [
      "we have successfully revolutionized the legal industry with our advanced AI-driven software, Lumins. Initially, we developed a conveyancing tool that automates data extraction from various legal documents, accurately converting them into the necessary documentation for property transactions. This phase focused on reducing manual entry errors, streamlining the conveyancing process, and ensuring compliance with legal standards.",
      "Building on this, we expanded the software's capabilities to handle a broader range of legal documents, such as wills, contracts, and corporate filings. By creating a generalizable framework, we minimized the need for document-specific code, enhancing the software's adaptability. This phase also introduced advanced client interaction features, improving real-time document review and secure communication.",
      "Ultimately, we developed a comprehensive legal tool supporting various legal activities, from routine document preparation to complex legal filings. Designed for scalability and integration, the software caters to small legal firms and large enterprises alike, providing a user-friendly interface and robust security measures. By automating data extraction and document generation, Lumins has reduced the time and cost associated with legal transactions, allowing legal professionals to focus on higher-value tasks and improve client satisfaction.",
    ],
    mes: [
      "We have successfully developed the Pharmaceutical Unified Platform, a comprehensive system designed to seamlessly connect patients, doctors, and pharmacists. This innovative platform streamlines the prescription process, enabling doctors to prescribe medications, pharmacists to process them, and patients to monitor their prescription statuses. Additionally, it facilitates cross-border prescription functionality between Canada and the US, integrating payment structures and subscription models to enhance the user experience.",
      "The platform’s objectives include creating a robust connection between patients, doctors, and pharmacists, ensuring efficient prescription creation, processing, and delivery. It supports cross-border prescription processing, establishing a transparent payment and subscription model for users. The Pharmaceutical Unified Platform also integrates with popular software systems, laying the foundation for future AI applications and includes a dedicated website to promote and provide access to the platform.",
      "Key features of the platform include a map-based search tool for finding nearby pharmacies, a tracking system for prescription refills, and comprehensive cross-border prescription management. Patients can easily change their pharmacy preferences, request cross-border refills, and consent to share information with other physicians. The platform also offers detailed payment tracking for patients and pharmacies, including subscription fees and commission structures, ensuring a smooth and transparent financial process for all users.",
    ],
    kyc: [
      "We developed an internal KYC and compliance software solution for one of the top three largest petrochemical producers in the Middle East. Previously, the company's KYC and compliance processes were entirely manual, relying on a collection of forms and folders.",
      "Our custom software automates and systematizes these processes. When a potential customer initiates the KYC process, their email is added to the platform, and they automatically receive a form and a list of required documents. The submitted documents are then screened and categorized by the software, and the customer's registration status is updated. An email is sent outlining which components of the process are complete, which documents are rejected, and which are still pending. The software screens company names, natural persons, addresses, phone numbers, IP addresses, and other relevant information. If any identifying information appears duplicated across registered companies, both companies are flagged to ensure tender legitimacy.",
      "Using a proprietary algorithm developed with the client’s risk department, registered companies are flagged based on fraud risk. The software also checks the names of individuals and companies against EU and OFAC sanctions lists, automatically generating and verifying alternative spellings of names. Once all documentation is complete, emails are sent to relevant third parties to verify the legitimacy of the documents. Upon approval of the registration, the platform integrates the customer's data into the company's CRM.",
    ],
    commodity: [
      "We collaborated with a multi-billion dollar commodity trading firm to create software that identifies arbitrage opportunities in physical commodity trading across Asia Pacific, South-East Asia, CIS, and MENA. This sophisticated tool extracts data from nine different sources, including local physical mercantile exchanges and commodity future markets, gathering information on supply, demand, and pricing. Additionally, freight price indexes and commodity price publications such as Platts, ICIS, and Argus are compiled into the database, along with market sentiment data generated using AI.",
      "Our software analyzes this comprehensive dataset using both traditional quantitative methods and neural networks, based on a custom algorithm developed in collaboration with our client. The result is real-time market insights shared directly with traders, providing live alerts to their phones and empowering them to make informed trading decisions.",
    ],
  };

  useEffect(() => {
    if (window.screen.width < 1200) {
      return;
    }

    const projectImages = document.getElementById("project-images");
    const projectDetails = document.getElementById("project-details");
    const lastItemPosition = document
      .querySelectorAll(".project-image")
      [
        document.querySelectorAll(".project-image").length - 1
      ].getBoundingClientRect().left;

    let detailsTransform = 0;
    let transform = 0;
    let opacity = 1;

    window.addEventListener("wheel", (event) => {
      const delta = Math.sign(event.deltaY);
      if (delta === 1 && Math.abs(transform) < lastItemPosition - 400) {
        transform -= 50;
        if (opacity > 0) {
          opacity -= 0.15;
          detailsTransform -= 10;
          projectDetails.style.opacity = opacity;
          projectDetails.style.transform = `translateX(${detailsTransform}px)`;
        } else {
          detailsTransform = -50;
          opacity = 0;
        }
      } else if (transform < 0) {
        if (transform >= -400) {
          opacity += 0.15;
          detailsTransform += 10;
          projectDetails.style.opacity = opacity;
          projectDetails.style.transform = `translateX(${detailsTransform}px)`;
        }
        transform += 50;
      } else if (transform === 0) {
        opacity = 1;
        detailsTransform = 0;
        projectDetails.style.opacity = opacity;
        projectDetails.style.transform = `translateX(${detailsTransform}px)`;
      }
      projectImages.style.transform = `translateX(${transform}px)`;
    });
  }, []);

  return (
    <motion.div
      className="project-detail-main"
      initial={{ opacity: 0, scale: 0.5, duration: 1 }}
      animate={{ opacity: 1, scale: 1, duration: 1 }}
      exit={{ opacity: 0, scale: 0.5, duration: 1 }}
    >
      <div className="container-fluid">
        <div className="col-12">
          <div className="project-detail">
            <div className="project-detail-left" id="project-details">
              <h1>{projectTitle}</h1>
              <div className="services-content">
                <div className="content">
                  {contents[name].map((item) => (
                    <p>{item}</p>
                  ))}
                </div>

                {/* <div className="services-detail">
                                    <h4>SERVICES</h4>
                                    <ul>
                                        <li>Concept</li>
                                        <li>Game Design</li>
                                        <li>Web Design</li>
                                        <li>Web Development</li>
                                        <li>3D Design</li>
                                        <li>WebGL</li>
                                    </ul>
                                </div> */}
              </div>
              {/* <a href="#">
                                <span>
                                    <span className="text">
                                        LAUNCH PROJECT
                                    </span>
                                </span>
                                LAUNCH PROJECT
                            </a> */}
            </div>
            <div className="project-detail-right" id="project-images">
              <div
                className="project-image"
                style={{ backgroundImage: `url(${imageUrl})` }}
              ></div>
              {/* <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div
                className="project-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div> */}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectDetail;
