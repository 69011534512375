import { motion } from "framer-motion";

import './About.scss';

const About = () => {
    return (
        <motion.div className="about-main" initial={{ opacity: 0, scale: 0.5, duration: 1 }} animate={{ opacity: 1, scale: 1, duration: 1 }} exit={{ opacity: 0, scale: 0.5, duration: 1 }}>
            <div className="container-fluid">
                <div className="page-content">
                    <div className="about">
                        <div className="col-12">
                            <div className="section-header">
                                <h1>ABOUT US</h1>
                            </div>
                            <div className="section-text">
                                <p>
                                    AI Infinity Labs was established in 2019 by two partners, each bringing over 25 years of software development management experience. Since inception, we've served diverse clients including Canadian law firms, Singaporean financial institutions, and Middle Eastern producers. With a global network of skilled programmers, we specialize in delivering top-tier custom software solutions for businesses. Our approach involves translating ideas into reality by exploring possibilities and collaborating closely with management teams.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default About; 