import { NavLink } from "react-router-dom";
import twoDots from '../../assets/icons/svg/two-dots.svg';
import arrowRight from '../../assets/icons/svg/arrow-right.svg';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const toggleMenu = () => {
    document.getElementsByClassName('menu-bar')[0].classList.toggle('active');
}

const Header = (currentPage) => {
    gsap.registerPlugin(useGSAP);

    useGSAP(() => {
        gsap.from('.header-text', {
            opacity: 0,
            y: 100,
            duration: 0.7,
            ease: 'power3.inOut',
            // delay: 1,
            onComplete() {
                gsap.to('.header-text', {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: 'power3.inOut',
                });
            },
        });
        gsap.from('.menu-bar', {
            opacity: 0,
            y: 60,
            duration: 0.5,
            ease: 'power3.inOut',
            delay: 0.3,
            onComplete() {
                gsap.to('.menu-bar', {
                    opacity: 1,
                    y: 0,
                    duration: 0,
                    ease: 'power3.inOut',
                });
            },
        });
    });

    return (
        <div className="site-main-header">
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 col-4">
                            <div className="header-left">
                                <NavLink to="/" aria-current="page" >INFINITY LABS.</NavLink>

                                {!currentPage.currentPage.includes('project-detail') && !currentPage.currentPage.includes('projects') && !currentPage.currentPage.includes('about') ?
                                    <div className="header-text d-lg-block d-none">
                                        <div className="line">Customized Enterprise Solutions</div>
                                        <div className="line">AI Integration for Success</div>
                                        <div className="line">Transforming Ideas into Reality</div>
                                    </div> : currentPage.currentPage.includes('projects') || currentPage.currentPage.includes('about') ? '' :
                                    <NavLink to="/projects" className="back-btn d-lg-flex d-none">
                                        <img src={arrowRight} alt="arrow" />
                                        BACK
                                    </NavLink>}
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-5 col-8">
                            <div className="header-right menu-bar">
                                <a href="#site-main-footer" className="lets-talk d-sm-block d-none">
                                    LET'S TALK
                                </a>
                                <div className="menu-button" onClick={toggleMenu}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="text-menu">MENU</span>
                                        <span className="text-close">CLOSE</span>
                                        <img src={twoDots} alt="two-dots" />
                                    </div>
                                </div>
                                <div className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <ul>
                                        <li className="nav-item">
                                            <NavLink to="/" className={(navDate) => navDate.isActive ? "nav-link active" : "nav-link"} aria-current="page" onClick={toggleMenu}>
                                                HOME
                                                <img src={arrowRight} alt="arrow" />
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about" className={(navDate) => navDate.isActive ? "nav-link active" : "nav-link"} onClick={toggleMenu}>
                                                ABOUT US
                                                <img src={arrowRight} alt="arrow" />
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/projects" className={(navDate) => navDate.isActive ? "nav-link active" : "nav-link"} onClick={toggleMenu}>
                                                PROJECTS
                                                <img src={arrowRight} alt="two-dots" />
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#site-main-footer" onClick={toggleMenu}>
                                                CONTACT
                                                <img src={arrowRight} alt="two-dots" />
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="d-sm-none d-block">
                                        <li className="nav-item">
                                            <a href="#site-main-footer" onClick={toggleMenu}>
                                                LET'S TALK
                                                <img src={arrowRight} alt="two-dots" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header