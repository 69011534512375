import { createRoot } from 'react-dom/client'
import App from './App';
import './assets/style/_global.scss';
import { BrowserRouter } from 'react-router-dom';
import NotificationCard from './components/NotificationCard';

createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <App />
        <NotificationCard />
    </BrowserRouter>
)