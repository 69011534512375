import LusionModel from "../../components/home/LusionModel";
import ProjectsList from "../../components/ProjectsList";
// import cardBg from "../../assets/images/sample.png";
import React, { useEffect } from "react";

import cardBg from "../../assets/images/home-test-2.webp";

import gsap from "gsap";

import { motion } from "framer-motion";

import "./Home.scss";

const Home = () => {
  useEffect(() => {
    const sectionMiddleTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".middle-text",
        start: "top bottom",
      },
    });
    sectionMiddleTimeline
      .from(".left-text", { y: 300, opacity: 0, duration: 1 })
      .to(".left-text", { y: 0, opacity: 1, duration: 1 })
      .from(".right-text", { x: 300, opacity: 0, duration: 1 }, "<")
      .to(".right-text", { x: 0, opacity: 1, duration: 1 }, "<");

    setTimeout(() => {
      // Element with class .columns
      const grid = document.querySelector(".featured-work");
      // All the columns class .column
      const columns = [...grid.querySelectorAll(".column")];
      // Map each column to its array of items and keep a reference of the image, its wrapper and the column
    //   const items = columns.map((column, pos) => {
    //     return [...column.querySelectorAll(".card")].map((item) => ({
    //       element: item,
    //       column: pos,
    //       wrapper: item.querySelector(".card-img-wrap"),
    //       image: item.querySelector(".card-image"),
    //     }));
    //   });
    //   // All itemms
    //   const mergedItems = items.flat();

    //   // Set perspective
    //   gsap.set([grid, mergedItems.map((item) => item.element)], {
    //     perspective: 600,
    //   });

    //   // Define GSAP timeline with ScrollTrigger
    //   gsap
    //     .timeline({
    //       defaults: { ease: "power2" },
    //       scrollTrigger: {
    //         trigger: grid,
    //         start: "clamp(top bottom)",
    //         end: "clamp(bottom top)",
    //         scrub: true,
    //       },
    //     })
    //     .to(
    //       columns[0],
    //       {
    //         startAt: { transformOrigin: "100% 50%" },
    //         rotationY: 8,
    //         z: 20,
    //       },
    //       0
    //     )
    //     .to(
    //       columns[1],
    //       {
    //         startAt: { transformOrigin: "100% 50%" },
    //         rotationY: 4,
    //       },
    //       0
    //     )
    //     .to(
    //       columns[2],
    //       {
    //         startAt: { transformOrigin: "0% 50%" },
    //         rotationY: -3,
    //       },
    //       0
    //     )
    //     .to(
    //       columns[3],
    //       {
    //         startAt: { transformOrigin: "0% 50%" },
    //         rotationY: -6,
    //         z: 20,
    //       },
    //       0
    //     );

    //   mergedItems.forEach((item) => {
    //     gsap.fromTo(
    //       item.wrapper,
    //       {
    //         opacity: 0,
    //         rotationX: -90,
    //         transformOrigin: "50% 0%",
    //       },
    //       {
    //         scrollTrigger: {
    //           trigger: item.element,
    //           start: "top bottom",
    //           end: "clamp(center center-=25%)",
    //           scrub: true,
    //         },
    //         opacity: 1,
    //         rotationX: 0,
    //       }
    //     );
    //   });
    }, 1000);

    return () => {
      sectionMiddleTimeline.kill();
    };
  }, []);

  return (
    <motion.div
      className="home-main"
      initial={{ opacity: 0, duration: 1 }}
      animate={{ opacity: 1, duration: 1 }}
      exit={{ opacity: 0, scale: 0.5, duration: 1 }}
    >
      <div className="container-fluid">
        <div className="col-12">
          <div className="model-3d">
            <div className="lusion">
              <LusionModel />
              <div className="scroll-to-explore d-md-flex d-none align-items-center justify-content-between">
                <span className="plus">+</span>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="plus">+</span>
                  <span className="title">SCROLL TO EXPLORE</span>
                  <span className="plus">+</span>
                </div>
                <span className="plus">+</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="col-12">
            <div className="bottom-banner-giant-text">
              <h1 className="text-left">Software Consulting</h1>
            </div>
          </div>

          <div className="row justify-content-end middle-text">
            <div className="col-md-6 col-12">
              <div className="right-text">
                <p>
                  Consult with our experts to design the perfect product
                  tailored to your business needs.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="bottom-banner-giant-text">
              <h1 className="text-left">AI Solutions</h1>
            </div>
          </div>

          <div className="row justify-content-end middle-text">
            <div className="col-md-6 col-12">
              <div className="right-text">
                <p>
                  Optimize your systems and operations with our advanced AI and
                  ML solutions, driving automation and peak performance.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="bottom-banner-giant-text">
              <h1 className="text-left">Digital Transformation</h1>
            </div>
          </div>

          <div className="row justify-content-end middle-text">
            <div className="col-md-6 col-12">
              <div className="right-text">
                <p>
                  Utilize our extensive experience to transform your business,
                  regardless of its size or industry.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="giant-text">
              <h1 className="text-left">Driving</h1>
              <h1 className="text-left">Digital Evolution</h1>
            </div>
          </div>

          <div className="experiences">
            <div className="row align-items-center">
              <div className="col-md-5 col-12">
                <div className="image">
                  <img src={cardBg} alt="ex" />
                </div>
              </div>
              <div className="col-md-7 col-12">
                <div className="text">
                  <p>
                    Welcome to AI Infinity Labs, where cutting-edge technology
                    meets tailored solutions. We work with diverse companies to
                    bring their ideas to life and transition their businesses
                    from paper to digital excellence. We specialize in
                    developing customized enterprise software that empowers
                    businesses to thrive. With our innovative AI integration, we
                    revolutionize the way software operates, bringing a
                    transformative experience to your fingertips. Embrace the
                    future of enterprise software – it's time to unlock the
                    power of AI for your business.
                  </p>
                </div>
                <div className="text">
                  <p>
                    We collaborate with businesses to deeply understand their
                    processes and integrate them with modern software, ensuring
                    your operations run faster, smoother, and with greater
                    precision. By automating key workflows and leveraging AI
                    integration, we transform your business processes. Our
                    mission is to assess your needs and create custom software
                    solutions, specifically tailored to empower your business to
                    achieve its fullest potential.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="featured-work">
            <div className="section-header">
              <div className="row align-items-end">
                <div className="col-md-8">
                  <h2>Featured Work</h2>
                </div>
                <div className="col-md-4">
                  <p>
                    We value our clients’ privacy. As such, we can’t divulge too
                    much detail about our past projects as they are proprietary
                    software. Below, you’ll find an overview of some of the
                    proprietary software we’ve developed for our clients.
                  </p>
                </div>
              </div>
            </div>

            <ProjectsList />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
