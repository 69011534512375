import arrowRight from '../../assets/icons/svg/arrow-right.svg';
import { NavLink } from "react-router-dom";

const Footer = () => {
    const styles = {
        info: {
            paddingBottom: '48px'
        }
    }

    return (
        <div className="site-main-footer" id="site-main-footer">
            <div className="row">
                <div className="col-12">
                    <div className="footer-brand">
                        <NavLink to="/" aria-current="page" >INFINITY LABS.</NavLink>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                    <div className="address">
                        <p>We are based in</p>
                        <p>Vancouver, British Columbia</p>
                        <p>Canada</p>
                    </div>

                    <p className="terms">@2024 INFINITY LABS.</p>
                </div>
                <div className="col-lg-3 col-md-6 col-12" style={styles.info}>
                    <div className="links">
                        <a href="#">Twitter</a>
                        <a href="#">Instagram</a>
                        <a href="#">Linkedin</a>
                    </div>

                    <div className="general-enq">
                        <p>General enquires</p>
                        <a href="#">hello@ai8x.io</a>
                    </div>

                    <div className="new-business">
                        <p>New business</p>
                        <a href="#">business@ai8x.io</a>
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="newsletter">
                        <h2>Subscribe to</h2>
                        <h2>our newsletter</h2>
                        <form>
                            <input type="text" name="newsletter" id="newsletter" placeholder="Your email" />
                            <button>
                                <img src={arrowRight} alt="two-dots" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Footer